const TurboFlashHelper = class {
  constructor() {
    document.addEventListener('turbo:before-fetch-response', (event) => {
      // 先にflashメッセージを出す
      var json = JSON.parse(
        event.detail.fetchResponse.header('X-Flash-Messages')
      )

      // メッセージを表示する
      for(let key in json){
        console.log(key + ':' + json[key])
        flash_submit(
          key,
          decodeURI(json[key])
        )
      }
      // turbo frameから リダイレクトを望まれていたらリダイレクトする
      if(event.detail.fetchResponse.header('X-Turbo-Connect') && typeof (event.detail.fetchResponse) !== 'undefined') {
        var response = event.detail.fetchResponse.response
        if (response.redirected) {
          console.log('need redirect')
          event.preventDefault()
          Turbo.visit(response.url, {action: 'replace'})
        }
      }
    })
  }
}
export default new TurboFlashHelper();
