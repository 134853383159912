import { Controller } from "stimulus";

export default class extends Controller {
  // TIPS: initializeは使わない事
  // 使いたくなったら設計に問題がある
  // セッションを通して一度しか実行したくないコントローラーの場合は
  // ajax-loaderなどを参考に、isLoadingValueなどを設定して
  // 1度だけ動かす事
  initialize() {
    this.controller_name = this.identifier;
    $(document).on(
      `stimulus.${this.controller_name}`,
      function (event, callback) {
        callback(this);
      }.bind(this)
    );
  }

  connect() {}

  disconnect() {
    $(document).off(`stimulus.${this.controller_name}`);
  }
}
