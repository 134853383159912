import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["popup"];

  connect() {
    this.close();
  }

  disconnect() {
    this.close();
  }

  open(e) {
    e.preventDefault();
    this._lockScroll();
    // モーダルにクラスを適用
    this.popupTarget.className =
      "fixed inset-0 z-[9999] bg-black bg-opacity-60 w-screen flex overflow-x-hidden overflow-y-scroll";
    $(this.popupTarget).show();
  }

  close(e) {
    this._unlockScroll();
    $(this.popupTarget).hide();
  }

  _lockScroll() {
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    this._saveScrollPosition();
    document.body.classList.add("fixed", "inset-x-0", "overflow-hidden");
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  _unlockScroll() {
    document.body.style.paddingRight = null;
    document.body.classList.remove("fixed", "inset-x-0", "overflow-hidden");
    this._restoreScrollPosition();
    document.body.style.top = null;
  }

  _saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  _restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition;
  }
}
