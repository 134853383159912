import ApplicationController from "./application_controller";
import { useIntersection } from "stimulus-use";

export default class extends ApplicationController {
  static values = {
    hospitalCode: String,
  };

  connect() {
    this.is_view = false;
    useIntersection(this);
  }

  appear(entry) {
    // callback automatically triggered when the element
    // intersects with the viewport (or root Element specified in the options)
    if (!this.is_view) {
      var self = this;
      console.log(this.hospitalCodeValue);
      this.is_view = true;
      gtag("event", "病院表示", {
        event_category: self.hospitalCodeValue,
        event_label: location.href,
        send_to: "UA-250188517-2",
      });

      gtag("event", "病院表示", {
        event_action: "病院表示",
        event_category: self.hospitalCodeValue,
        event_label: location.href,
        send_to: "G-DRE933HD8C",
      });
    }
  }

  disappear(entry) {
    // callback automatically triggered when the element
    // leaves the viewport (or root Element specified in the options)
    console.log("disappear");
  }

  increase() {}
  decrease() {}
}
